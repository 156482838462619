import React from "react"
import {graphql} from "gatsby"

import GraphQLErrorList from '../components/graphql-error-list'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Grid from "../components/grid"

export const query = graphql`
  query IndexPageQuery
  {
    site: sanitySiteSettings {
      description
      keywords
    }
    grid: sanityGrid {
      text
      title
    }
    weddings: allSanityWedding(
      limit: 5
      filter: {onGrid: {eq: true}},
      sort: {order: DESC, fields: _createdAt}
    ) {
      nodes {
        date
        mainImage {
          alt
          asset {
            fluid(maxWidth: 1353) {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
        planner
        plannerUrl
        subtitle
        title
        venue
        slug {
          current
        }
        rowsGallery {
          imagesGallery {
            asset {
              url
              fluid(maxWidth: 2336) {
                ...GatsbySanityImageFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
`

const IndexPage = (props) => {
  const
    {data, errors} = props,
    site = (data || {}).site,
    weddings = (data || {}).weddings.nodes,
    grid = (data || {}).grid;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio and add some content to "Settings" and restart the development server.'
    )
  }

  return (
    <React.Fragment>
      <SEO title="Home" description={site.description} keywords={site.keywords} />

      <div id="index">
        <h1 data-sal="slide-right" data-sal-easing="ease" style={{"--sal-duration": "3.5s", "--sal-delay": "1.2s"}}>{grid.title}</h1>
        <p data-sal="slide-right" data-sal-easing="ease" style={{"--sal-duration": "2.5s", "--sal-delay": "1.2s"}}>{grid.text}</p>

        <Grid weddings={weddings} />

      </div>
    </React.Fragment>
  )
}

export default IndexPage
