import React from 'react'
import {Link} from 'gatsby'

function Grid (props) {
  const {weddings} = props

  const duration = (key) => {
    let secs
    switch (key) {
      case 0:
      case 5:
        return secs = "3s"
      case 1:
      case 4:
        return secs = "4s"
      default:
        return secs = "2s"
    }
  }
  const delay = (key) => {
    let secs
    switch (key) {
      case 0:
      case 5:
        return secs = "1s"
      case 1:
      case 4:
        return secs = "1s"
      case 7:
      return secs = "1.3s"
      default:
        return secs = "1s"
    }
  }

  return (
    <React.Fragment>
      {
        weddings.map((wedding, key) => {
          const {mainImage, slug, title, venue} = wedding
          return (
            <div id={`w0${key + 1}`} key={key} data-sal="slide-up" data-sal-easing="ease-out-cubic" style={{"--sal-duration": duration(key), "--sal-delay": delay(key)}}>
              <div style={{backgroundImage: `url(${mainImage.asset.fluid.src})`}} className={`index--wedding-container w0${key + 1}`}>
                <Link className="grid--link-desktop" to={`/wedding/${slug.current}`}>
                  <div>
                    <h4>{title}</h4>
                  </div>
                  <div>
                    <p>{venue}</p>
                  </div>
                </Link>
              </div>
              <Link className={`grid--link-mobile w0${key + 1}`} to={`/wedding/${slug.current}`}>
                <h4>{title}</h4>
                <p className="venue-mobile">{venue}</p>
              </Link>
            </div>
          )
        })
      }
    </React.Fragment>
  )
}

export default Grid
